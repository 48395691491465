.introjs-custom * {
  font-family: "Inter";
}

.introjs-custom {
  background: #ffffff;

  box-shadow: 0px 1px 1px rgba(100, 116, 139, 0.06),
    0px 1px 2px rgba(100, 116, 139, 0.1);
  border-radius: 8px;
  border: 0;
}

.introjs-tooltip-title {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 250%;
  /* identical to box height, or 30px */

  letter-spacing: 0.5px;
  text-transform: uppercase;

  /* Primary/Main */

  color: #29a776;
}

.introjs-bullets {
  padding: 1rem;
  text-align: right;
  color: #6b7280;
}

.introjs-bullets ul li a.active {
  background: #6b7280;
  width: 6px;
}

.introjs-bullets ul li a:hover {
  background: #6b7280;
}

.introjs-tooltipbuttons a {
  background: rgba(41, 167, 118, 0.08);
  border: 1px solid #29a776;
  border-radius: 32px;
  font-weight: 600;
  font-family: "Inter";
  color: #29a776;
}

.introjs-tooltipbuttons a:hover,
.introjs-tooltipbuttons a:focus {
  background: #29a776;
  color: #fff;
  text-shadow: none;
}
