body {
  white-space: pre-line;
  font-size: 1.6rem;
}

/* Uncomment the following line to change rem to 10px */
html {
  font-size: 10px;
}

@font-face {
  font-family: "NexaBold";
  src: local("NexaBold"),
    url("../../public/fonts/Nexa Bold/NexaBold.woff") format("woff"),
    url("../../public/fonts/Nexa Bold/NexaBold.svg#NexaBold") format("svg"),
    url("../../public/fonts/Nexa Bold/NexaBold.eot"),
    url("../../public/fonts/Nexa Bold/NexaBold.eot?#iefix")
      format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NexaRegular";
  src: local("NexaRegular"),
    url("../../public/fonts/Nexa Regular/NexaRegular.woff") format("woff"),
    url("../../public/fonts/Nexa Regular/NexaRegular.svg#NexaRegular")
      format("svg"),
    url("../../public/fonts/Nexa Regular/NexaRegular.eot"),
    url("../../public/fonts/Nexa Regular/NexaRegular.eot?#iefix")
      format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}
